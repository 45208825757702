// types
import type { Nullable, Student } from '@revolutionprep/types'

// nuxt
import { useNuxtApp } from '#app'

export function usePage (title: Ref<string>) {
  /**
   * head properties
   * ==================================================================
   */
  useHead({
    title: `${title.value} | Revolution Prep`
  })

  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor } = useNuxtApp()

  /**
   * computed
   * ==================================================================
   */
  const actor = computed(() => {
    return $actor.core.actor.value as Nullable<Student>
  })

  const actorId = computed(() => {
    return $actor.core.actorId.value
  })

  const storageValues = computed(() => {
    return [
      'scheduleSessionsStep',
      'scheduleSessionsHeading',
      `sessionDetails-${actorId.value}`,
      `sessionDuration-${actorId.value}`,
      `sessionSubjectId-${actorId.value}`
    ]
  })

  return {
    actor,
    storageValues
  }
}
